import Handsontable from 'handsontable';

export const partner_profile_table_constants = {
  PARTNER_LK_HOT_TABLE_COLUMNS: [
    {
      value: 'id',
      title: 'ID заявки',
      width: 70,
      readOnly: true
    },
    {
      value: 'calcClaimDate',
      title: 'Дата формирования',
      width: 100,
      readOnly: true
    },
    {
      value: 'address',
      title: 'Адрес объекта',
      width: 400,
      readOnly: true
    },
    {
      value: 'calcClaimStatus',
      title: 'Статус заявки',
      width: 100,
      readOnly: true
    },
    {
      value: 'roles',
      title: 'Дата выполнения',
      width: 100,
      readOnly: true,
    },
    {
      value: 'checkResult',
      title: 'Результат проверки',
      width: 120,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        if (value == 'рекомендован') td.innerHTML = `<a style="color: green">${value}</a>`;
        else td.innerHTML = `<a style="color: red">${value}</a>`;
      },
    },
    {
      value: 'checkResult',
      title: 'Отчет',
      width: 100,
      readOnly: true,
      renderer: function(instance, td, row, col, prop, value) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        if (!value) {
          return;
        }
        td.innerHTML = `<a class="mt-1 mb-1 link-secondary">${value}</a>`;
      },
    }
  ]
};
