import { partner_profile_table_constants } from '@/lib/handsontable/partner-profile/partner_profile_table_constants.js';
import Handsontable from 'handsontable';

const BASE_COLUMN_WIDTH = 140;

export class UsersHotTable {
  #container = null;
  #height = null;
  #language = null;
  #data = null;
  #hotInstance = null;
  #filterFields = ['firstName', 'roles', 'email', 'contactInfo'];
  #tableColumns = partner_profile_table_constants.PARTNER_LK_HOT_TABLE_COLUMNS;

  colWidths() {
    const arr = [];

    this.#tableColumns.forEach((el) => {
      const objWidth = el.width || BASE_COLUMN_WIDTH;

      arr.push(objWidth);
    });

    return arr;
  }

  hot() {
    this.#hotInstance = new Handsontable(this.#container, {
      data: this.#data,
      colHeaders: true,
      stretchH: 'all',
      height: this.#height,
      width: 1100,
      rowHeaders: true,
      language: this.#language,
      columns: this.#tableColumns,
      columnSorting: true,
      colWidth: this.colWidths(),
      filters: false,
      invalidCellClassName: 'myInvalidClass',
      beforeRefreshDimensions() { return false; },
      licenseKey: process.env.VUE_APP_HANDSONTABLE_LICENSE_KEY,
    });
  }

  init(args) {
    this.#container = args.container;
    this.#height = args.height;
    this.#language = args.language;
    this.#data = args.data;

    this.hot();
  }
}

const usersHotTable = new UsersHotTable();

export default usersHotTable;
