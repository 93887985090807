<template>
  <div class="handsontable-wrap min-height no-style">
    <h2 class="pt-2 pl-2">Список заявок</h2>
    <div ref="partnerCalcClaim"
         class="calc-claim-list"></div>
  </div>
</template>

<script>
import partnerHotTable from '@/lib/handsontable/partner-profile/partner_profile_table.js';
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n';

registerLanguageDictionary(ruRU);
const HEADER_HEIGHT = 51;

export default {
  computed: {
    tableHeight() {
      return window.innerHeight - HEADER_HEIGHT - 50;
    },
  },
  mounted() {
    const params = {
      container: this.$refs.partnerCalcClaim,
      height: this.tableHeight,
      language: ruRU.languageCode,
      data: [
        [120, "18.11.2021", "Москва, ул. Озёрная, д. 12, кв. 29", 'в работе', "", '', ''],
        [119, "18.11.2021", 'Москва, ул. Академика Королева, д. 9, К 1, кв. 241', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [118, "18.11.2021", 'Москва, ул. Борисовская, д. 21, кв. 27', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [117, "18.11.2021", 'Москва, ул. Яблочкова, д. 43, кв. 54', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [116, "18.11.2021", "Москва, ул. Дубининская, д. 88, кв. 94", 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [115, "18.11.2021", 'Москва, ш. Пятницкое, д. 31, кв. 26', 'готова', "19.11.2021", 'не рекомендован', 'Смотреть'],
        [114, "18.11.2021", 'Москва, ул. Часовая, д. 19, к 3, кв. 132', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [113, "18.11.2021", 'Москва, проезд Береговой, д 1А, кв. 520', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [112, "18.11.2021", "Красногорск, ул. Почтовая, д 16, кв. 216", 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [111, "18.11.2021", 'Москва, ул. Куусинена, д. 6, К 12, кв. 980', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [110, "18.11.2021", 'Одинцово, ул. Северная, д. 28, кв. 30', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [109, "18.11.2021", 'Москва,  ул. Лукинская, д. 6, кв. 134', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [108, "18.11.2021", "Москва, ул. Бакунинская, д. 44-48, стр. 1, кв. 33", 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [107, "18.11.2021", 'Москва, Алтуфьевское ш., д. 96, кв 469', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [106, "18.11.2021", 'Москва, ул. Введенского, д. 10, к. 2, кв. 74', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [105, "18.11.2021", 'Москва, ул. Обручева, д. 24, кв. 171', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [104, "18.11.2021", "Москва, ул. 13-я Парковая, д. 28, к 2, кв 72", 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [103, "18.11.2021", 'Люберцы, ул. Преображенская, д. 4, кв. 3', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [102, "18.11.2021", 'Москва,  ул. Молдагуловой, д. 16, К 1, кв. 4', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
        [101, "18.11.2021", 'Москва, ул. Ухтомская, д. 9, кв. 82', 'готова', "19.11.2021", 'рекомендован', 'Смотреть'],
      ],
    };

    partnerHotTable.init(params);
  }
}
</script>
